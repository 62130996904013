import { SimpleMaskMoney } from "./SimpleMaskMoney";

export const  _form = function myForm(){
    var pub = {
        validateInit: function(settings,fun){
            if(settings.phone){
                pub.phoneFormat(settings.phone);
            }
            if(settings.zip){
                pub.zipFormat(settings.zip);
            }
            if(settings.price){
                pub.priceFormat(settings.price);
            }


            var el = document.querySelector(settings.id);
            if(el){
                el.addEventListener('submit', function(evt){
                    evt.preventDefault();

                    pub.check(settings.id,settings,fun);
                });
            }
        },
        check: function(form,settings,fun){
            var errorClassName = settings.errorClassName;

            var status = true;

            status = pub.checkElement(status,form,settings,'input');

            status = pub.checkElement(status,form,settings,'textarea');

            status = pub.checkElement(status,form,settings,'select');

            if(settings.email){
                if(document.querySelectorAll(form+' '+settings.email.element)){
                    var elements = document.querySelectorAll(form+' '+settings.email.element);

                    for(var i = 0; i < elements.length; i++){
                        var value = elements[i].value;
                        if(!pub.validEmail(value)){
                            status = false;

                            elements[i].classList.add(errorClassName);
                        }
                    }
                }
            }

            if(status){
                if(settings.email) {
                    if (document.querySelectorAll(form + ' ' + settings.email.element)) {
                        var elements = document.querySelectorAll(form + ' ' + settings.email.element);

                        for (var i = 0; i < elements.length; i++) {
                            elements[i].classList.remove(errorClassName);
                        }
                    }
                }

                fun(true);
            }else{
                fun(false);
            }
        },
        checkElement: function(status,form,settings,element){
            var errorClassName = settings.errorClassName;

            var elements = document.querySelector(form).getElementsByTagName(element);
            for(var i = 0; i < elements.length; i++){
                var value = elements[i].value;
                var type = elements[i].type;
                var required = elements[i].required;

                if(required){
                    if(value == '' && type != 'radio' && type != 'checkbox'){
                        status = false;

                        elements[i].classList.add(errorClassName);
                    }

                    if(type == 'radio' || type == 'checkbox'){
                        if (elements[i].checked != true) {
                            status = false;
                        }
                    }
                }
            }

            return status;
        },
        priceFormat: function(settings){
            const options = {
                prefix: '$',
                suffix: '',
                fixed: true,
                fractionDigits: 2,
                decimalSeparator: '.',
                thousandsSeparator: ',',
                autoCompleteDecimal: true
            };

            SimpleMaskMoney.setMask(settings.element, options);
        },
        zipFormat: function(settings){
            var elements = document.querySelectorAll(settings.element);

            for (var i = 0; i < elements.length; i++){
                elements[i].addEventListener('input', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})/);
                    e.target.value = x[1];
                });
            }
        },
        phoneFormat: function(settings){
            var elements = document.querySelectorAll(settings.element);
            for (var i = 0; i < elements.length; i++){
                elements[i].addEventListener('input', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                });
            }
        },
        accountNumberFormat: function(settings){
            var elements = document.querySelectorAll(settings.element);
            for (var i = 0; i < elements.length; i++){
                elements[i].addEventListener('input', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,14})/);
                    e.target.value = x[1];
                });
            }
        },
        routingNumberFormat: function(settings){
            var elements = document.querySelectorAll(settings.element);
            for (var i = 0; i < elements.length; i++){
                elements[i].addEventListener('input', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,9})/);
                    e.target.value = x[1];
                });
            }
        },
        validEmail: function(val){
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(val);
        },
        serialize: function(form){
            var field, l, s = {};
            if (typeof form == 'object' && form.nodeName == "FORM") {
                var len = form.elements.length;
                for (var i=0; i<len; i++) {
                    field = form.elements[i];
                    if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' &&
                        field.type != 'submit' && field.type != 'button') {
                        if(field.name != '__proto__'){
                            if (field.type == 'select-multiple') {
                                l = form.elements[i].options.length;
                                for (j=0; j<l; j++) {
                                    if(field.options[j].selected)
                                        s[field.name] = field.options[j].value;
                                }
                            } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
                                s[field.name] = field.value;
                            }
                        }
                    }
                }
            }

            return s;
        }
    };

    return pub;
}();

