/*mask*/
export const SimpleMaskMoney = function(){
    var pub = {
        get args() {
            return _args;
        },
        set args(value) {
            _args = Object.assign(_args, value);
            _args.prefix = value.prefix || value.preffix;
        },

        onlyNumber: value => {
            let retorno = '';

            for (let i = 0; i < value.length; i++) {
                if (isFinite(value[i])) {
                    retorno += value[i];
                }
            }

            return retorno;
        },

        addingPrefix: value => {
            return `${pub.args.prefix}${value}`;
        },
        removingPrefix: value => {
            return value.replace(pub.args.prefix, '');
        },

        addingSuffix: value => {
            return `${value}${pub.args.suffix}`;
        },
        removingSuffix: value => {
            if (
                value.includes(
                    pub.args.suffix,
                    value.length - pub.args.fractionDigits
                )
            ) {
                value = value.replace(pub.args.suffix, '');
            } else {
                value = value.substring(0, value.length - 1);
            }
            return value;
        },

        addingCompleterFromStart: (value, completer) => {
            while (value.length < pub.args.fractionDigits) {
                value = `${completer}${value}`;
            }
            return value;
        },

        addingCompleterFromEnd: (value, completer) => {
            while (value.length < pub.args.fractionDigits) {
                value = `${value}${completer}`;
            }
            return value;
        },

        removingCompleterFromStart: (value, completer) => {
            while (value[0] === completer) {
                value = value.replace(completer, '');
            }
            return value;
        },

        removingCompleterFromEnd: (value, completer) => {
            while (value[value.length - 1] === completer) {
                value = value.substring(0, value.length - 1);
            }
            return value;
        },

        addingAutoComplete: value => {
            const n = `${value}${pub.addingCompleterFromEnd(
                '',
                '0'
            )}`;
            return n;
        },

        autoComplete: value => {
            const array =
                value.match(
                    new RegExp(`\\${pub.args.decimalSeparator}`, 'g')
                ) || [];
            if (array.length > 1) {
                value = pub.addingAutoComplete(value);
            }
            return value;
        },

        addingDecimalSeparator: (value, completer, separator) => {
            let length = value.length - pub.args.fractionDigits;

            let regexpFraction;
            let decimals = '$1';
            let dezenas = completer;
            let character = isFinite(completer) ? 'd' : 'w';

            regexpFraction = `(\\${character}{${pub.args
                .fractionDigits}})`;
            if (length > 0) {
                regexpFraction = `(\\${character}{${length}})${regexpFraction}`;
                dezenas = decimals;
                decimals = '$2';
            }

            return value.replace(
                new RegExp(regexpFraction),
                `${dezenas}${separator}${decimals}`
            );
        },

        addingHundredsSeparator: value => {
            let size = value.length - pub.args.fractionDigits;
            let hundreds = Math.ceil(size / 3);
            let regexpHundreds = '(\\d)';

            let replacement = `${pub.args
                .decimalSeparator}$${hundreds + 1}`;

            for (let i = hundreds; i !== 0; i--) {
                if (size >= 3) {
                    regexpHundreds = `(\\d{3})${regexpHundreds}`;
                    size -= 3;
                } else {
                    regexpHundreds = `(\\d{${size}})${regexpHundreds}`;
                }

                if (i > 1) {
                    replacement = `${pub.args
                        .thousandsSeparator}$${i}${replacement}`;
                } else {
                    replacement = `$${i}${replacement}`;
                }
            }

            return value.replace(new RegExp(regexpHundreds), replacement);
        },
        removeSeparator: (value, separator) => {
            return value.replace(new RegExp(`\\${separator}`, 'g'), '');
        },
        formatDecimal: (value, completer, separator) => {
            value = pub.addingCompleterFromStart(value, completer);
            value = pub.addingDecimalSeparator(
                value,
                completer,
                separator
            );
            return value;
        },

        textToNumber: input => {
            let retorno = input.toString();
            let completer = pub.args.fixed ? '0' : '_';

            if (pub.args.prefix) {
                retorno = pub.removingPrefix(retorno);
            }

            if (pub.args.suffix) {
                retorno = pub.removingSuffix(retorno);
            }

            if (pub.args.autoCompleteDecimal) {
                retorno = pub.autoComplete(retorno);
            }

            retorno = pub.removeSeparator(
                retorno,
                pub.args.thousandsSeparator
            );
            retorno = pub.removeSeparator(
                retorno,
                pub.args.decimalSeparator
            );

            retorno = pub.onlyNumber(retorno);

            retorno = pub.removingCompleterFromStart(
                retorno,
                completer
            );

            return retorno || (pub.args.fixed ? '0' : '');
        },

        numberToText: input => {
            let retorno = pub.args.emptyOrInvalid();

            if (parseFloat(input) !== 'NaN') {
                if (input.length <= pub.args.fractionDigits) {
                    retorno = pub.formatDecimal(
                        input,
                        pub.args.fixed ? '0' : '_',
                        pub.args.decimalSeparator
                    );
                } else {
                    retorno = pub.addingHundredsSeparator(input);
                }
            }

            if (pub.args.prefix) {
                retorno = pub.addingPrefix(retorno);
            }
            if (pub.args.suffix) {
                retorno = pub.addingSuffix(retorno);
            }

            return retorno;
        },

        format: value => {
            return pub.numberToText(
                pub.textToNumber(value)
            );
        },

        formatToNumber: input => {
            let retorno = '0';
            let value = pub.textToNumber(input);

            if (parseFloat(value) !== 'NaN') {
                if (value.length <= pub.args.fractionDigits) {
                    value = pub.formatDecimal(value, '0', '.');
                } else {
                    let lengthWithoutDecimals =
                        value.length - pub.args.fractionDigits;
                    value = value.replace(
                        new RegExp(
                            `(\\d{${lengthWithoutDecimals}})(\\d{${pub.args
                                .fractionDigits}})`
                        ),
                        '$1.$2'
                    );
                }

                retorno = value;
            }

            return parseFloat(retorno);
        },

        setMask: (element, args) => {
            let input = typeof element == 'string' ? document.querySelector(element) : element;

            if (args) {
                pub.args = args;
            }

            input.addEventListener('input', () => {
                input.value = pub.format(input.value);
            });
            input['formatToNumber'] = () => {
                return pub.formatToNumber(input.value);
            };

            return input;
        }
    };

    let _args = {
        prefix: '',
        suffix: '',
        fixed: true,
        fractionDigits: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        autoCompleteDecimal: false,
        emptyOrInvalid: () => {
            return pub.args.fixed
                ? `0${pub.args.decimalSeparator}00`
                : `_${pub.args.decimalSeparator}__`;
        }
    };

    return pub;
}();