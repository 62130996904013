import Selectr from "mobius1-selectr";
import Simplebar from "simplebar";
//import { elements } from "../baseView";

const birthdaySelectsSettings = ['DD', 'MM', 'YY'];

export const initBirthdaySelects = (elements) => {
    elements.forEach( (cur, idx) => {
        const selector = new Selectr(cur, {
            searchable: false,
            placeholder: birthdaySelectsSettings[idx],
            defaultSelected: false,
            customClass: 'custom-select',
        });

        new Simplebar(selector.tree.closest('.selectr-options-container'));
    });
};

export const initCustomSelects = (elements) => {
    elements.forEach(cur => {
       const selector = new Selectr(cur, {
           searchable: false,
           defaultSelected: false,
           customClass: 'custom-select',
       });

        new Simplebar(selector.tree.closest('.selectr-options-container'));
    });
};

export const initCustomSelectsPlaceholder = (elements) => {
    elements.forEach(cur => {
        const selector = new Selectr(cur, {
            searchable: false,
            defaultSelected: false,
            placeholder: cur.dataset.placeholder,
            customClass: 'custom-select',
        });

        new Simplebar(selector.tree.closest('.selectr-options-container'));
    });
};


const renderIconTpl = (option) => {
    return `
        <div class="custom-icon-select">
            <svg class="custom-icon-select__icon">
                <use xlink:href="${option.parentElement.getAttribute('data-url')}/images/sprite.svg#${option.parentElement.getAttribute('data-select-icon')}"></use>
            </svg>
            
            <span class="custom-icon-select__text">${option.textContent}</span>
        </div>`;
};

let selector = null;

export const initCustomIconSelects = (elements) => {
    if (elements) {
        if(selector != null){
            selector.reset();
        }

        elements.forEach(cur => {
            const selector = new Selectr(cur, {
                renderSelection: renderIconTpl,
                searchable: false,
                customClass: 'custom-select',
            });

            new Simplebar(selector.tree.closest('.selectr-options-container'));
        });
    }
};