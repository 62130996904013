export const  _ajaxObj = function(){
    var pub = {
        post: function(param,fun){
            var formData;

            formData = new FormData();
            if(param.data){
                var object1 = param.data;
                for (var property1 in object1) {
                    formData.append(property1,object1[property1]);
                }
            }

            setTimeout(function(){
                var ajax = new XMLHttpRequest();

                ajax.open("POST", param.url, true);

                ajax.onload = function () {
                    if (this.status) {
                        if (this.status == 200 && (this.readyState == 4)){
                            fun(this.responseText);
                        }
                    }
                };

                if(param.progressFun){
                    ajax.upload.addEventListener("progress", function (event) {
                        var percent = (event.loaded / event.total) * 100;
                        percent = parseInt(percent);

                        if(percent > 100){
                            percent = percent - 100;
                        }

                        param.progressFun(percent);
                    });
                }

                ajax.setRequestHeader("Cache-Control", "no-cache");

                ajax.send(formData);
            },100);
        }
    }

    return pub;
}();