import {_ajaxObj} from "../../../../js/library/ajax";
import {_sweetalert} from "../../../../js/library/sweetalert";
import {_form} from "../../../../js/library/form";
import {showPreloader, hidePreloader} from "../../../js/preloader/preloader";

export const _forgotPasswordForm = function(){
    var formId = 'forgotPasswordForm';

    var pub = {
        init(){
            var form = document.querySelector('#'+formId);
            form.addEventListener('submit', function(e) {
                e.preventDefault();

                var email;

                email = document.querySelector('#'+formId+' input[name="email"]').value;
                if(email != ''){
                    if(_form.validEmail(email)){
                        showPreloader();

                        var formEl = document.querySelector('#'+formId);

                        var data = _form.serialize(formEl);

                        var settings = {
                            data:data,
                            url:'registration/forgotPassword'
                        };
                        _ajaxObj.post(settings,pub.handler);
                    }else{
                        _sweetalert.errorTxt('Enter valid email');
                    }
                }else{
                    _sweetalert.errorRequiredFields();
                }
            });
        },
        handler(response){
            hidePreloader();

            try {
                var obj = JSON.parse(response);
                if (obj.status == true){
                    _sweetalert.successTxt(obj.message);
                } else {
                    _sweetalert.errorTxt(obj.message);
                }
            } catch (e) {
                _sweetalert.errorTryAgainLater();
            }
        }
    };

    return pub;
}();