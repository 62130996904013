import {_ajaxObj} from "../../../../js/library/ajax";
import {_sweetalert} from "../../../../js/library/sweetalert";
import {_form} from "../../../../js/library/form";
import {_login} from "./login";
import {showPreloader, hidePreloader} from "../../../js/preloader/preloader";

export const _registration = function(){
    var formId = 'registration';

    var pub = {
        init(){
            var settings = {
                element:'#'+formId+' input[name="phone"]'
            };

            _form.phoneFormat(settings);

            var playTrigger = document.querySelector('#'+formId);
            playTrigger.addEventListener('submit', function(e) {
                e.preventDefault();

                var firstName, lastName, email, phone, password, confirmPassword, birthDay, birthMonth, birthYear;

                firstName = document.querySelector('#'+formId+' input[name="firstName"]').value;
                lastName = document.querySelector('#'+formId+' input[name="lastName"]').value;
                email = document.querySelector('#'+formId+' input[name="email"]').value;
                phone = document.querySelector('#'+formId+' input[name="phone"]').value;
                password = document.querySelector('#'+formId+' input[name="password"]').value;
                confirmPassword = document.querySelector('#'+formId+' input[name="confirmPassword"]').value;
                birthDay = document.querySelector('#'+formId+' select[name="birthDay"]').value;
                birthMonth = document.querySelector('#'+formId+' select[name="birthMonth"]').value;
                birthYear = document.querySelector('#'+formId+' select[name="birthYear"]').value;

                if(firstName != '' && lastName != '' && email != '' && phone != '' && password != '' && confirmPassword != '' && birthDay != '' && birthMonth != '' && birthYear != ''){
                    if(_form.validEmail(email)){
                        showPreloader();

                        var formEl = document.querySelector('#'+formId);

                        var data = _form.serialize(formEl);

                        var settings = {
                            data:data,
                            url:'registration'
                        };
                        _ajaxObj.post(settings,pub.handler);
                    }else{
                        _sweetalert.errorTxt('Enter valid email');
                    }
                }else{
                    _sweetalert.errorRequiredFields();
                }
            });
        },
        handler(response){
            hidePreloader();

            try {
                var obj = JSON.parse(response);

                if (obj.status == true){
                    _sweetalert.successTxt(obj.message);


                    if(window.parent){
                        parent.postMessage(response,"*");
                    }

                    _login.showVerifyForm();
                } else {
                    _sweetalert.errorTxt(obj.message);
                }
            } catch (e) {
                _sweetalert.errorTryAgainLater();
            }
        }
    };

    return pub;
}();