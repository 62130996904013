import {initBirthdaySelects} from "../../selects/selects";
import {_ajaxObj} from "../../../../js/library/ajax";
import {_sweetalert} from "../../../../js/library/sweetalert";
import {_form} from "../../../../js/library/form";
import {showPreloader, hidePreloader} from "../../../js/preloader/preloader";

export const _login = function(){
    var formId = 'login';

    var pub = {
        init(){
            var birthDaySelect = document.querySelectorAll('.js_birthday-select');

            initBirthdaySelects(birthDaySelect);

            var registerFormClick = document.querySelector('.js_showRegisterForm');
            registerFormClick.addEventListener('click', function(e) {
                pub.showRegisterForm();
            });

            var forgotPasswordFormClick = document.querySelector('.js_showForgotPassword');
            forgotPasswordFormClick.addEventListener('click', function(e) {
                pub.showForgotPassword();
            });

            var backToLoginFormClick = document.querySelectorAll('.js_backToLogin');
            backToLoginFormClick.forEach(function(el){
                el.addEventListener('click', function(e) {
                    pub.showLoginForm();
                });
            });

            var registerFormClick = document.querySelectorAll('.js_showRegisterForm');
            registerFormClick.forEach(function(el){
                el.addEventListener('click', function(e) {
                    pub.showRegisterForm();
                });
            });


            var playTrigger = document.querySelector('#'+formId);
            playTrigger.addEventListener('submit', function(e) {
                e.preventDefault();

                var email, password;

                email = document.querySelector('#'+formId+' input[name="email"]').value;
                password = document.querySelector('#'+formId+' input[name="password"]').value;

                if(email != '' && password != ''){
                    if(_form.validEmail(email)){
                        showPreloader();

                        var formEl = document.querySelector('#'+formId);

                        var data = _form.serialize(formEl);

                        var settings = {
                            data:data,
                            url:'registration/login'
                        };
                        _ajaxObj.post(settings,pub.handler);
                    }else{
                        _sweetalert.errorTxt('Enter valid email');
                    }
                }else{
                    _sweetalert.errorRequiredFields();
                }
            });
        },
        handler(response){
            hidePreloader();
            try {
                var obj = JSON.parse(response);
                if (obj.status == true){
                    if(window.parent){
                        parent.postMessage(response,"*");
                    }

                    location.reload();
                } else {
                    if(obj.code){
                        if(obj.code == 'confirmEmail'){
                            pub.showVerifyForm();
                        }
                    }
                    _sweetalert.errorTxt(obj.message);
                }
            } catch (e) {
                _sweetalert.errorTryAgainLater();
            }
        },
        showLoginForm(){
            document.querySelector('#registration').parentElement.style.display = 'none';
            document.querySelector('#login').parentElement.style.display = 'block';
            document.querySelector('#verifyForm').parentElement.style.display = 'none';
            document.querySelector('#forgotPasswordForm').parentElement.style.display = 'none';
        },
        showVerifyForm(){
            document.querySelector('#registration').parentElement.style.display = 'none';
            document.querySelector('#login').parentElement.style.display = 'none';
            document.querySelector('#forgotPasswordForm').parentElement.style.display = 'none';
            document.querySelector('#verifyForm').parentElement.style.display = 'block';
        },
        showRegisterForm(){
            document.querySelector('#registration').parentElement.style.display = 'block';
            document.querySelector('#login').parentElement.style.display = 'none';
            document.querySelector('#verifyForm').parentElement.style.display = 'none';
            document.querySelector('#forgotPasswordForm').parentElement.style.display = 'none';
        },
        showForgotPassword(){
            document.querySelector('#registration').parentElement.style.display = 'none';
            document.querySelector('#login').parentElement.style.display = 'none';
            document.querySelector('#verifyForm').parentElement.style.display = 'none';
            document.querySelector('#forgotPasswordForm').parentElement.style.display = 'block';
        }
    };

    return pub;
}();