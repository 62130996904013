// Icons
// import "./bell-i.svg";
// import "./man-i.svg";
// import "./settings-i.svg";
// import "./download-i.svg";
// import "./money-i.svg";
// import "./payment-i.svg";
// import "./send-i.svg";
// import "./wallet-i.svg";
// import "./calendar-i.svg";
// import "./search-i.svg";
// import "./star-i.svg";
// import "./bank-i.svg";
// import "./breadcrumb-decor-i.svg";
// import "./payment-method-i.svg";
// import "./reward-balance-i.svg";
// import "./close-i.svg";
// import "./burger-i.svg";
// import "./exit-i.svg";
// import "./check-i.svg";
// import "./card-i.svg";
// import "./number-i.svg";
// import "./cvv-i.svg";
// import "./checkmark-i.svg";
// import "./plus-i.svg";
// import "./minus-i.svg";