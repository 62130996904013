const preloaderElement = document.querySelector('.page-loader');
const preloaderWrapper = document.querySelector('.page-loader__wrapper');

export const showPreloader = () => {
    document.body.style.overflow = 'hidden';

    preloaderWrapper.classList.remove('page-loader__wrapper--hidden');

    setTimeout(() => {
        preloaderElement.classList.remove('page-loader--hidden');
    }, 200);
};

export const hidePreloader = () => {
    preloaderElement.classList.add('page-loader--hidden');

    setTimeout(() => {
        preloaderWrapper.classList.add('page-loader__wrapper--hidden');
        document.body.style.overflow = 'visible';
    }, 300);
};