import Swal from "sweetalert2";
import {showPreloader, hidePreloader} from "../../dev/js/preloader/preloader";

export const _sweetalert = function() {
    var pub = {
        modalAnswer(settings){
            Swal.fire({
                title: settings.title,
                text: settings.text,
                type: settings.type,
                buttonsStyling: false,
                confirmButtonClass: 'button',
                background: '#fff'
            });

            hidePreloader();
        },
        modalConfirmAnswer(fun, settings){
            Swal.fire({
                title: settings.title,
                text: settings.text,
                type: settings.type,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonClass: 'button',
                confirmButtonText: settings.buttonText,
                cancelButtonClass: 'button button_cancel',
                dangerMode: true,
                background: '#fff'
            }).then(function(willResult){
                if (willResult.value) {
                    if(fun) {
                        if (fun != '') {
                            fun(settings);
                        }
                    }
                }
            });

            hidePreloader();
        },
        errorRequiredFields: function(){
            var settings = {
                title:'Error',
                text:'Please complete all required fields.',
                type:'error'
            };

            pub.modalAnswer(settings);
        },
        confirm: function(id,msg,fun){
            var settings = {
                'title': 'Are you sure?',
                'text': msg,
                'type': 'warning',
                'buttonText': 'Confirm',
                'id': id
            };

            pub.modalConfirmAnswer(fun, settings);
        },
        successTxt: function(txt){
            var settings = {
                title:'Success',
                text:txt,
                type:'success'
            };
            pub.modalAnswer(settings);
        },
        errorTxt: function(txt){
            var settings = {
                title:'Error',
                text:txt,
                type:'error'
            };
            pub.modalAnswer(settings);
        },
        errorTryAgainLater: function(){
            var settings = {
                title:'Error',
                text:'Sorry there was an error try again later.',
                type:'error'
            };
            pub.modalAnswer(settings);
        }
    };

    return pub;
}();