import "../../sprite/sprite";

import {_login} from "../../js/pages/auth/login";
import {_forgotPasswordForm} from "../../js/pages/auth/forgotPasswordForm";
import {_activateForm} from "../../js/pages/auth/activateForm";
import {_registration} from "../../js/pages/auth/registration";
import {hidePreloader} from "../../js/preloader/preloader";

document.addEventListener('DOMContentLoaded', () => {
    _settings.init();

    setTimeout(() => {

        hidePreloader();
    }, 1000);
});

var _settings = function(){
    var pub = {
        init(){
            _login.init();
            _forgotPasswordForm.init();
            _activateForm.init();
            _registration.init();
        }
    };

    return pub;
}();